import { useTranslation } from "react-i18next";
import "./index.css";
function Footer() {
  const { t } = useTranslation();
  return (
    <footer className="footer">
      <div className="container">
        <div className="footer__wrapper">
          <p>{t("footerTitle")}</p>
          <a href="https://www.westarted.com.tr" className="text-dark"><p>{t("footerAdmin")}</p></a>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
