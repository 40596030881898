import img from "../../Images/logo.png"
import { useState, useEffect } from "react"
import "./loader.css";
function Loader() {
    const [height, setHeight] = useState()
    useEffect(() => {
        setHeight(window.innerHeight)
    })
    return (
        <div style={{ height }} className="loaader-body">
            <span class="loader my-auto"></span>
            <h5 className="my-auto my">RAPQON DRY FRUITS EXPORT</h5>
            
        </div>
    )
}
export default Loader;