import AboutUs from "../Components/AboutUs";
import ContactUs from "../Components/Contact us";
import Footer from "../Components/Footer";
import Header from "../Components/Header/index";
import Introduction from "../Components/Introduction";
import OurFamousProducts from "../Components/Our famous Product";
import ProductList from "../Components/Product list";
import Products from "../Components/Products";
import Quality from "../Components/Quality";
import './app.css';
import { useEffect, useState } from "react";
import { BrowserRouter } from 'react-router-dom';
import Loader from "../Components/Loader/loader";
import img from "../Images/contact.png";
import img1 from "../Images/email.png";
import img2 from "../Images/ins.png";
import img3 from "../Images/tg.png";
import img4 from "../Images/phone.png";
import img5 from "../Images/wt.png";

function App() {
  const [loading, setLoading] = useState(true);
  const handleClick = () => {

    document.getElementById("phone").classList.toggle("img-contact-phone")
    document.getElementById("instagram").classList.toggle("img-contact-instagram")
    document.getElementById("whatsapp").classList.toggle("img-contact-whatsapp")
    document.getElementById("telegram").classList.toggle("img-contact-telegram")
    document.getElementById("email").classList.toggle("img-contact-email")

  }
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 5000);
  }, []);


  return (
    <div >
      {loading ? (
        <Loader />
      ) : (
        <BrowserRouter>
          <>
            <Header></Header>
            <Introduction />
            <ProductList />
            <Quality />
            <AboutUs />
            <Products />
            <OurFamousProducts />
            <ContactUs />
            <Footer />
            <img src={img} onClick={handleClick} className="img-contact"></img>
            <a href="tel:+998909178111"><img src={img4} id="phone" className="img"></img></a>
            <a href="https://www.instagram.com/abdumalikov757__/"><img src={img2} id="instagram" className="img"></img></a>
            <a href="https://wa.me/998909178111"><img src={img5} id="whatsapp" className="img"></img></a>
            <a href="https://t.me/Abdumalikov1998800"><img src={img3} id="telegram" className="img"></img></a>
            <a href="mailto:rapqondryfruits@gmail.com"><img src={img1} id="email" className="img"></img></a>

          </>
        </BrowserRouter>
      )}
    </div>
  );
}

export default App;
