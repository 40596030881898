import './index.css'
import firstImage from '../../Images/fuits2.jpg'
import secondImage from '../../Images/fruits.jpeg'
import { useTranslation } from 'react-i18next';
function Quality() {
  const { t } = useTranslation();
  const quality = t('quality', { returnObjects: true });
  return (
    <section className="quality">
      <div className="container">
        <div className="quality__wrapper">
          <div className="quality__left">
            <img className='quality__image' src={firstImage} alt='firstImage'/>
            <p className='quality__text'>     {quality[0].title}</p>
            <p className='quality__suptext'>{quality[0].text}</p>
          </div>
          <div>
          <h2 className="quality__main__title">
             {t(`qualityTitle`)}
            </h2>
          <div className="quality__right">
            <img className='quality__image' src={secondImage} alt='secondImage'/>
            <p className='quality__text'>     {quality[1].title}</p>
            <p className='quality__suptext'>{quality[1].text}</p>
          </div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default Quality;
