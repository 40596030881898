import React, { useState } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import img from "../../Images/logo.png";
import { LANGUAGES } from "./languages";
import "./index.css";
import { useTranslation } from "react-i18next";

function Header() {
  const [collapsed, setCollapsed] = useState(true);
  const { i18n, t } = useTranslation();
  const toggleNavbar = () => setCollapsed(!collapsed);
  const onChangeLang = (e) => {
    const lang_code = e.target.value;
    i18n.changeLanguage(lang_code);
  };
  return (
    <div className="container">
      <div className="border-bottom d-flex mt-1 me-2">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-geo-alt" viewBox="0 0 16 16">
          <path d="M12.166 8.94c-.524 1.062-1.234 2.12-1.96 3.07A32 32 0 0 1 8 14.58a32 32 0 0 1-2.206-2.57c-.726-.95-1.436-2.008-1.96-3.07C3.304 7.867 3 6.862 3 6a5 5 0 0 1 10 0c0 .862-.305 1.867-.834 2.94M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10" />
          <path d="M8 8a2 2 0 1 1 0-4 2 2 0 0 1 0 4m0 1a3 3 0 1 0 0-6 3 3 0 0 0 0 6" />
        </svg>
        <span className="text-secondary ms-2">
        Besharik District, Fergana Region, O'zbekiston
        </span>
      </div>
      <Navbar color="faded" light>
        <NavbarBrand href="/" className="">
          <img src={img} className="imgIcon"></img>
        </NavbarBrand>
        <div className="mx-auto div-number">
          <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="Green" class="bi bi-telephone-fill my-auto me-2" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
          </svg>
          <h1>+998 90 917 81 11</h1>
        </div>
        <select className="nav__select" defaultValue={i18n.language} onChange={onChangeLang}>
          {LANGUAGES.map(({ code, label }) => (
            <option key={code} value={code}>
              {label}
            </option>
          ))}
        </select>
        <NavbarToggler onClick={toggleNavbar} className="me-2" />
        <hr />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar className="">
            <NavItem>
              <NavLink href="#products">Products</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#Aboutus">About us</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#famousProduct">Our famous products</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#contactUs">Contact us</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}
export default Header;
