import { useTranslation } from "react-i18next";
import "./index.css";
function AboutUs() {
  const { t } = useTranslation();
  return (
    <section id="Aboutus" className="about-us">
      <div className="container">
        <div className="about-us__wrapper">
          <div className="about-us__left">
            <h2 className="about-us__main-title">{t("aboutUsTitle")}</h2>
            <h3 className="about-us__title">{t("aboutUsText")}</h3>
            <p className="about-us__text">{t("aboutUsDesc")}</p>
          </div>
          <div className="about-us__right">
            <div className="about-us__image"></div>
          </div>
        </div>
      </div>
    </section>
  );
}
export default AboutUs;
