import "./index.css";
import firstImage from "../../Images/pngwing.com (5).png";
import secondImage from "../../Images/fruitsCut.png"
import thirdImage from "../../Images/driedFruit.png"
import { useTranslation } from "react-i18next";
function ProductList() {
  const { t } = useTranslation();
  const Images = [firstImage,thirdImage,secondImage]
  return (
    <section className="productslist">
      <div className="container">
        <ul className="productslist__items">
          {t("productsList", { returnObjects: true }).map((products, index) => (
            <li key={index} className="productslist__item">
              <img
                src={Images[index]}
                className="productslist__image"
                alt="productslist"
              />
              <h4 className="productslist__title">{products.title}</h4>
              <p className="productslist__text">{products.text}</p>
              <a className="productslist__link" href="#">
                {products.button}
              </a>
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
export default ProductList;
