import img5 from "../../Images/sliva.jpg"
import img1 from "../../Images/060yvpgn.png"
import img2 from "../../Images/raisin.jpg"
import img3 from "../../Images/alimond.jpg"
import img4 from "../../Images/walnut.jpeg" 
import "./index.css";

import { useTranslation } from "react-i18next";
function OurFamousProducts() {
  const { t } = useTranslation();
  const images = [img1, img2, img3, img4, img5]
  return (
    <section id="famousProduct" className="famous-products">
      <div className="container">
        <h2 className="famous-products__main__title">{t("products")}</h2>
        <ul className="famous-products__list">

        {t("ourFamousAll", { returnObjects: true }).map((product, index) => (
            <li key={index} className={`famous-products__item ${index+1> 2 ? "famous-products__item-sm": ""}`}>
           
            <img className="famous-products__image" src={images[index] } />
            <div>
              <h4 className="famous-products__title">{product.productsTitle}</h4>
              <p className="famous-products__text">
              {product.productsText}
              </p>
            </div>
            </li>
          ))}
       

        
        </ul>
      </div>
    </section>
  );
}
export default OurFamousProducts;
