import { useTranslation } from 'react-i18next';
import './index.css'
function Products() {
  const { t } = useTranslation();
  return (
    <section id='products' className="products">
      <div className="container">
        <h2 className="products__main__title text-success">{t("products")}</h2>
        <ul className="products__list">

        {t("productsAll", { returnObjects: true }).map((product, index) => (
            <li key={index} className="products__item">
              <h4 className="products__title">{product.productsTitle}</h4>
            <ul className="products__item__list">
              {product.productsList.map((item, index)=>(
                 <li key={index} className="products__item__item">{item}</li>
              )

              )}
             
            </ul>
            </li>
          ))}

          
        </ul>
      </div>
    </section>
  );
}
export default Products;
