import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import uz from './uz.json';
import en from './en.json';
import ru from './ru.json';
i18n.use(initReactI18next).init({
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
  resources: {
    uz: { translation: uz },
    en: { translation: en },
    ru: { translation: ru }
  },
});

export default i18n;
