import { useTranslation } from 'react-i18next';
import './index.css'
function Introduction(){
    const { t } = useTranslation();
 // Intro qismidagi ma'lumotlarni olish
 const intro = t('intro', { returnObjects: true });
return (
<section className='intro'>
    <div className='intro__content'>
        <h4 className='intro__title'>{intro[0].title}</h4>
        <p className='intro__text'>
        {intro[0].text}
        </p>
        <a className='intro__link' href='#'>{intro[0].button}</a>
    </div>
</section>
)
}
export default Introduction;